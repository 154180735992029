<template>
  <div style="position: relative; overflow: hidden">
    <!-- <vue-aliplayer-v2 :ref="videoRef" :id="videoRef" :source="source" :options="options" /> -->
    <div id="dplayer" :ref="videoRef"></div>
    <!-- <H265Player
      :url="videoUrl"
      style="height:15vw"
    /> -->
    <div ref="playMask" class="play-mask">
      <div v-show="showLoading" class="ring-1"></div>
      <div v-show="!showLoading">
        <p>获取播放源失败</p>
        <div class="error-operation">
          <a class="btn" @click="retry">重试</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import DPlayer from "dplayer";
// import VueAliplayerV2 from "vue-aliplayer-v2";
// import H265Player from 'vue-h265-player';
import api from "../../../../util/api";
import axios from "../../../../util/extra-api";
export default {
  //   components: {
  //   H265Player,
  // },
  //   components: { VueAliplayerV2: VueAliplayerV2.Player },
  props: {
    id: String,
    isFeedback: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      videoRef: "",
      dPlayer: null,
      videoUrl: "",
      showLoading: true,
      value: 0,
    };
  },
  watch: {
    isFeedback(newVal, oldVal) {
      // console.log(newVal,'isFeedback');
      if (newVal) {
        if (this.dPlayer && this.dPlayer.video) {
          let player = this.dPlayer.video;
          let canvas = document.createElement("canvas");
          canvas.width = player.clientWidth;
          canvas.height = player.clientHeight;
          canvas
            .getContext("2d")
            .drawImage(player, 0, 0, canvas.width, canvas.height);
          let dataURL = canvas.toDataURL("image/png");
          axios
            .post("/v1/wx/upload/base64Upload", {
              imgData: dataURL,
            })
            .then((res) => {
              this.$emit("screenshot", res.data);
            });
        } else {
          this.$emit("screenshot", null);
        }
      }
    },
  },
  computed: {},
  mounted() {
    this.videoRef = `player-${this.id}`;
    this.init();
  },
  methods: {
    init() {
      api.get("/pc/company-cam/live", { id: this.id }).then((response) => {
        const data = response.data;
        this.videoUrl = data;
        const _this = this;
        this.dPlayer = new DPlayer({
          container: _this.$refs[_this.videoRef],
          live: true,
          mutex: false,
          screenshot: true,
          autoplay: true,
          volume: 0,
          video: {
            url: _this.videoUrl,
            type: "customHls",
            customType: {
              customHls: function (video, player) {
                _this.hls = new Hls();
                _this.hls.loadSource(_this.videoUrl);
                _this.hls.attachMedia(video);
                _this.hls.on(Hls.Events.ERROR, function (event, data) {
                  switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                      let videoContent = _this.$refs[_this.videoRef];
                      let h =
                        videoContent.clientHeight || videoContent.offsetHeight;
                      let playMask = _this.$refs.playMask;
                      playMask.style.height = h + "px";
                      playMask.style.display = "block";
                      if (_this.value < 5) {
                        _this.hlsTryLoadTimer = setTimeout(
                          () => _this.hlsTryLoad(),
                          3000
                        );
                      } else {
                        _this.showLoading = false;
                      }
                  }
                });
                _this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
                  _this.$refs.playMask.style.display = "none";
                });
              },
            },
          },
        });
        this.dPlayer.template.menu.remove();
        this.dPlayer.template.notice.remove();
        // setTimeout(() => {
        //   // console.log(this.dPlayer.video,123123);
        //   let player = this.dPlayer.video;
        //   var canvas = document.createElement("canvas");
        //   canvas.width = player.clientWidth;
        //   canvas.height = player.clientHeight;
        //   canvas
        //     .getContext("2d")
        //     .drawImage(player, 0, 0, canvas.width, canvas.height);
        //   var dataURL = canvas.toDataURL("image/png");
        // }, 6000);
        this.dPlayer.template.webFullButton.remove();
      });
    },

    hlsTryLoad() {
      this.hls.loadSource(this.videoUrl);
      this.hls.startLoad();
      this.value++;
    },
    retry() {
      api.get("/pc/company-cam/live/" + this.id).then((response) => {
        const data = response.data;
        this.videoUrl = data;
        const _this = this;
        _this.showLoading = true;
        _this.value = 0;
        _this.hlsTryLoad();
      });
    },
  },
  beforeDestroy() {
    this.hls.destroy();
    this.dPlayer.destroy();
  },
};
</script>
<style>
#dplayer {
  width: 100%;
  height: 320px;
}
.play-mask {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 99;
  overflow: hidden;
  text-align: center;
}
.play-mask .error-operation {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1.5px;
  border-bottom-color: rgba(255, 255, 255, 0.15);
  border-bottom-style: dotted;
  text-align: center;
}
.play-mask .error-operation .btn {
  width: 80px;
  height: 32px;
  line-height: 32px;
  vertical-align: top;
  display: inline-block;
  margin-left: 10px;
  text-decoration: none;
  color: #4b9cdb;
  border: solid 1px #4b9cdb;
  background-color: transparent;
  cursor: pointer;
}
.ring-1 {
  position: absolute;
  top: 40%;
  left: 45%;
  width: 10px;
  height: 10px;
  margin: 0 auto;
  padding: 10px;
  border: 3px dashed #4b9cdb;
  border-radius: 100%;
  animation: loadingD 1.5s 0.3s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}
@keyframes loadingD {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
